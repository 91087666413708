module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-source-wordpress/gatsby-browser.js'),
      options: {"plugins":[],"url":"https://dev-sitecraft.pantheonsite.io/graphql","verbose":true},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"SiteCraft","short_name":"SiteCraft","start_url":"/","background_color":"#0c090d","theme_color":"#ee9572","display":"minimal-ui","icon":"src/images/favicon-icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"4f79954964b094b4ce15c739465b3680"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
